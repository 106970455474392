<app-blank-header></app-blank-header>
<div class="container">
  <div>
    <span>
      <h2 class="top-heading">Terms and Conditions<br /></h2>
    </span>
  </div>
  <div>
    <h6>
      This document is an electronic record in terms of Information Technology
      Act, 2000 and rules there under as applicable and the amended provisions
      pertaining to electronic records in various statutes as amended by the
      Information Technology Act, 2000. This document is published in accordance
      with the provisions of Rule 3 (1) of the Information Technology
      (Intermediaries guidelines) Rules, 2011 that require publishing the rules
      and regulations, privacy policy and Terms of Use for access or usage of
      www.epanipuricart.com website and Epanipuricart application for mobile and
      handheld devices. <br />
    </h6>
  </div>
  <div>
    <span>Epanipurii kartz Terms of Use <br /> </span>
  </div>
  <div>
    <p>
      Welcome to Epanipurii kartz . To avail the services offered at Epanipurii kartz , 
      you must agree to the following terms and conditions. If you visit or shop or browse at Epanipurii kartz. 
      you accept these conditions.
    </p>
    <p>
      Please read the term and conditions carefully. While using any current or future services offered by Epanipurii kartz, 
      whether included in the Epanipurii kartz website/Application, you will abide by these Terms & conditions 
      the guidelines and conditions applicable to such service or business.
      <br />
    </p>
  </div>
  <div>
    <span>
      <h6>Privacy Policy <br /></h6>
    </span>
  </div>
  <div>
    <p>
      Please reviews our Privacy Policy, which also governs your visit to Epanipurii kartz, 
      to fully understand our practices. <br />
    </p>
  </div>

  <div>
  <div>
    <span>
      <h6>Terms and conditions are binding for all purchases<br /></h6>
    </span>
  </div>

    <div>
      <p>
        All orders are deemed offers for you to purchase our products. We may accept your offer by issuing a 
        confirmatory e-mail and/or mobile confirmation of the products specified in your order. Our acceptance
        of each such offer is expressly subject to and conditioned on your assent to these terms and conditions 
        of sale. No other terms and conditions will apply. <br />
      </p>
    </div>

    <div>
      <span>
        <h6>•	Communications<br /></h6>
      </span>
    </div>
    <div>
      <p>
        When You use the Website/Application or send emails or other data, information or communication to us, 
        You agree and understand that You are communicating with Us through electronic records and You consent 
        to receive communications via electronic records from Us periodically and as and when required. 
        We may communicate with you by email or by such other mode of communication.
      </p>
    </div>

    <div>
      <span>
        <h6>•	Electronic Communications<br /></h6>
      </span>
    </div>

    <div>
      <p>
        
        When you visit Epanipurii kartz , you are communicating with us electronically. By communicating with us, 
        you consent to receive communication from us electronically. We will communicate with you by e-mail or by 
        posting notices on our website. You agree that all agreements, notices, disclosures, and other communications
        that we provide to you electronically satisfy any legal requirement that such communication be in writing.
      </p>
    </div>
  </div>

  <div>
    <span>
      <h6>Prices <br /></h6>
    </span>
  </div>
  <div>
    <p>
      All prices posted on this website are subject to change without notice.
      Prices prevailing at commencement of placing the order will apply. Posted
      prices do includes all taxes and charges. In case there are any additional
      charges or taxes the same will be mentioned on the website. <br />
    </p>
  </div>
  <div>
    <span>
      <h6>Payment <br /></h6>
    </span>
  </div>
  <div>
    <p>
      All payments must be received by us prior to shipping as directed on the Website/Application. 
      We will updating the Payment Method on the Website/Application. Currently We accept payment by UPI, 
      net-banking, credit card, debit card and Payment gateway <br />
    </p>
  </div>
  <div>
    <span>
      <h6>Payment Refund policy <br /></h6>
    </span>
  </div>
  <div>
    <p>
      All payments received by us prior to shipping are liable to the refund , subject to approved & Confirmed 
      cancellation of the order , Any such amount , partial payment , excess or cancelled order are subject to 
      refund to the payee account within 7-10 working days or posted on the website/Application periodically 
      referred to any offer/scheme. <br />
    </p>
  </div>
  <div>
    <span>
      <h6>Shipping and Handling <br /></h6>
    </span>
  </div>
  <div>
    <!-- <p>
      We will arrange for shipment of the products to you. Shipping schedules
      are estimates only and cannot be guaranteed. Provided by the Logistics
      partner ,<strong> approx. 7-10 days depending on the zone.</strong> We are
      not liable for any delays in the shipments. Title and risk of loss and
      damages pass on to you upon the products delivery to you. In case reverse
      shipment cannot be arranged by us due to unavailability of our logistics
      partners, then in that case you may be requested to send such products
      through any available courier services. <br />
    </p> -->
    <p>
      Shipping method will be posted along with product description. We will indicate the Logistics method based on 
      Pincode Location. We will arrange for shipment of the products to you. . Provided by the Logistics partner , 
      approx. 7-10 days depending on the Pincode Location. All such information will be published or communicated 
      electronically on or before Order Confirmation. We are not liable for any delays in the shipments. 
      Title and risk of loss and damages pass on to you upon the products delivery to you. In case reverse shipment 
      cannot be arranged by us due to unavailability of our logistics partners, then in that case you may be requested 
      to send such products through any available courier services.
      <br />
    </p>
  </div>
  <div>
    <span>
      <h6>Return of Products by you <br /></h6>
    </span>
  </div>
  <div>
    <p>
      Return policy of the product will be published  Or communicated along with Order Confirmation. 
      We will accept the return of the products, provided such return is for products that are damaged during transit, 
      defective, wrongly delivered, wrong product, incomplete package etc. subject to the condition that we are 
      informed about such discrepancies within 48 hours from the date of receipt of the product and provided that 
      the products are returned in their original condition.<br />
    </p>
  </div>
  <div>
    <span>
      <h6>Changes and Cancellation <br /></h6>
    </span>
  </div>
  <div>
    <p>
      Any item additions, quantity changes or specification changes made to accepted orders will be modified in the order 
      details. All sales are final, provided, however, item cancellations and quantity reductions may be made before the 
      order is shipped. We may, without liability, cancel any accepted order before shipment or if there are other problems 
      with the payment mode selected by you.<br />
    </p>
  </div>

  <div>
  <div>
    <span>
      <h6>Refund <br /></h6>
    </span>
  </div>
  <div>
    <p>
      If any orders are cancelled by you before procurement,or before being shipped, 
      then Epanipurii kartz will charge restocking fee applicable as per the product category.  <br />
    </p>
  </div>

  <div>
    <span>
      <h6><i>Acceptable Methods of Payment</i> <br /></h6>
    </span>
  </div>

  <div>
    <p>
      Presently payment methods available with through Credit Cards, Debit Cards, Net banking ,UPI , 
      Payment Apps only. As and when other methods will be incorporated, the same would be updated here as well. 
      Your Credit/Debit card information remains private and confidential. We offer you a secure online payment
      gateways which uses Secure Sockets Layer security technology to protect your Card information. 
      We accept only Indian cards. No International cards are supported. For making payment VIA card you 
      need to enter your 16 digit Credit/Debit card number and the 3 digit CVV (Card Verification Value) 
      code which is at the back of your card and complete the payment.
      <br />
    </p>
  </div>

  <div>
    <span>
      <h6><i>Order Cancellation</i> <br /></h6>
    </span>
  </div>

  <div>
    <p>
      An order cannot be cancelled after shipment. If you wish to cancel your order, we can only cancel it before 
      it has been shipped. For cancellation, customer can reach 
      <br />
    </p>
    <p>
      2. Once your account is cancelled all your Content will be immediately deleted. Since deletion of all data 
      is final please be sure that you do in fact want to cancel your account before doing so. 
      <br />
    </p>
    <p>
      3.	If you cancel the Service in the middle of the month, you will receive one final invoice via email. 
      Once that invoice has been paid you will not be charged again.
      <br />
    </p>
    <p>
      4.	We reserve the right to modify or terminate the Epanipurii kartz service for any reason, 
      without notice at any time.
      <br />
    </p>
    <p>
      5.	Fraud: Without limiting any other remedies, Epanipurii kartz may suspend or terminate your account 
      if we suspect that you (by conviction, settlement, insurance, or escrow investigation, or otherwise) have 
      engaged in fraudulent activity in connection with the Site.
      <br />
    </p>

    <p>
      No refunds are offered, even if a plan is canceled mid-month. <br />
      Cancellation policy is not applicable on any Discounted schemes/offers.
      <br />
    </p>

  </div>

  <div>
    <span>
      <h6><i>Delivery</i> <br /></h6>
    </span>
  </div>

  <div>
    <p>
      We provides Delivery for all products sold to your beneficiary address. (Kindly check Pin Code for 
      Delivery Serviceable Area). There are no hidden charges applicable. Free Delivery will only be 
      applicable for the products offered under Free Delivery Category. Delivery Time differs for or 
      within 10Km radius from the store within the city where the product must be delivered. Delivery 
      time would be 5-6 or 7-10 (depending on the Zone/Pincode) working days from the day you place your 
      order for the Outstation Pincode Delivery Destinations.. Delivery Charges will be calculated basis 
      on the pincode and the logistic partner and the same will be communicated to the customer prior order
       confirmation. There could be delay in delivery due to unforeseen circumstances too. Any delay in the 
       delivery due to stock unavailability or some technical issues that will not considered in the shipment date/days.
      <br />
    </p>
  </div>

  <div>
    <span>
      <h6><i>Hassle Free Installation</i> <br /></h6>
    </span>
  </div>

  <div>
    <p>
      The Demonstration of products is provided by the manufacturing company and is provided on certain products only, 
      in case of further clarifications, we would request to contact us on (Phone Number) or email us on (Email ID) 
      and we will be happy to help you on the same.
      <br />
    </p>
  </div>

  <div>
    <span>
      <h6><i>Returns and Refunds</i> <br /></h6>
    </span>
  </div>

  <div>
    <p>
      In case you do not receive the products ordered by you and receive some other products, we will send the correct 
      order at no extra cost to you. Kindly write to us at Email ID and “send us photos of the wrongly delivered product 
      within 7 days of your receipt of the product. In case we are unable to source the products that you ordered we will 
      let you know by email along with an expected date for shipment.
      <br />
    </p>
  </div>

  <div>
    <span>
      <h6><i>Invoicing and Delivery</i> <br /></h6>
    </span>
  </div>

  <div>
    <p>
      As per the Indian Tax Regulations, all the products shall be shipped with a unique invoice number mentioning 
      the product price. The cost of shipping is calculated and displayed on the order status in website.
      It is our policy to deliver your order as per the terms & Conditions published within the Product Catagory.

      <br />
    </p>
  </div>

  <div>
    <span>
      <h6><i>Shipping</i> <br /></h6>
    </span>
  </div>

  <div>
    <p>
      Most of our orders are shipped and delivered within 5-7 working days. Our products are shipped on all days, 
      excluding public holidays. Although we strive hard to deliver your order on time, due to a load with the courier, 
      an estimated delay of 2-3 days might be expected. <br />
      We partner with only reputed courier agencies to ensure timely delivery of the products in good condition.<br />
      While we shall try to ship all the products of your order together, this may not be possible due to product 
      characteristics and availability of the products. Please refuse to accept the delivery and immediately 
      contact our Customer Care Centre if the product delivered is not in good condition, or damaged. We shall make 
      our best efforts to ensure a replacement delivery at the earliest. 
      <br />
    </p>
  </div>

  <div>
    <span>
      <h6><i>Refund of money due to non-delivery of the products/damaged goods</i> <br /></h6>
    </span>
  </div>

  <div>
    <p>
      In case of Refund due to non-delivery or any queries contact us at our email id. In case you do not receive any product 
      within stipulated Time frame of the delivery or there is no information or confirmation of Delivery within 7-10 days , 
       Kindly write to us at Email ID and send us details of your order . We will initiate the refund process. Or arrange for 
       the substitute delivery. Subject to the confirmation of Non-Delivery by our Logistics Partner.
      <br />
    </p>
  </div>


</div>



<div>
  <span>
    <h6>Return & Replacement Policy <br /></h6>
  </span>
</div>
<div>
  <p>
    We understand how much you value your every purchase. And we are committed to <br />
    We assure you that all products sold on Epanipurii kartz E-commerce are brand new and 100% genuine. 
    Our shipments go through rigorous quality check processes before they leave our warehouse. 
    However in the rare case that your product is damaged during shipment or transit, you can request 
    for a replacement. In case the product you received is 'Damaged', 'Defective' or 'Not as Described', 
    our Friendly Returns policy has got you covered.
   <br />
  </p>
  <p>
    You can conveniently initiate the return on our Website/Mobile site. We will ensure you get a resolution 
    as quickly as possible. Our Returns policy may vary slightly depending on the product.
    <br />
  </p>
  <ul>
    <li>
      Any Product that is 'Damaged', 'Defective' or 'Not as Described', purchased from the Website, 
      can be returned to the Company within three (3) days of delivery of the same to User by placing a
       "Return request” by choosing either of the following options: <br />
       *User can send a Return request to ouremail id  from registered email id with reason for Product Return
    </li>
    <li>
      The User shall ensure that he/she does not accept the delivery of any Product whose original packaging 
      is damaged or tampered in any manner.
    </li>
    <li>
      In the event if User, on removing the packaging of the Product, finds the Product is damaged and/or 
      defective and/or is different from the Product that was ordered, User shall immediately intimate the Company 
      through either of the above mentioned options about such damage and/or defect and/ or difference and the 
      Company shall arrange for the replacement of the Product or refund of price of the Product along with shipping 
      charges, if any, collected by the Company in the unlikely event of the Company being unable to replace the 
      damaged and/or defective and/or Not as Described Product. For any Refund or Exchange / Replacement of Product 
      the relevant provisions of Refund and Exchange shall apply as given herein.
    </li>
    <li>
      In case of Return / Replacement of order after delivery of the Product to User, the Company will arrange 
      pick up of the Product to be returned. User will assist and cooperate fully to return the Product with its manuals, 
      booklets, warranty card, accessories, freebies and packing materials or any other thing which were delivered to 
      User along with the product being returned. At the time of pickup the User will be provided with acknowledgement of 
      pick up by the Company Logistical Partner. User should retain the said acknowledgment and quote or product the same 
      in all his/her future communications with the Company in this regard.
    </li>
    <li>
      In case any free item delivered to User is found defective then the Company shall arrange the replacement 
      of the Free Item only and the main Product shall not be replaced.
    </li>
    <li>
      In case of any defect arising after 3 days of delivery of the Product or after demo/installation of the 
      Product as the case may be, such defects will be serviced by the respective manufacturer/brand in accordance 
      with respective manufacturer's warranty terms.
    </li>
    <li>
      In case of those products that are covered for demo and / or installation by the manufacturer through 
      their authorized service partner and are found to be damaged / defective, then the user shall obtain from the 
      service partner Service Job Sheet, wherein the nature of issue is clearly detailed by the service partner, 
      duly signed. Photograph detailing damage/different product and the service job sheet to be scanned and sent by 
      e-mail to us from registered email id or upload it while logging in the My account.
    </li>
    <li>
      For those products which are applicable to be installed by a manufacturers’ authorized service partner, 
      if the User gets the product installed through an unauthorized person / company, then the warranty shall be 
      void and no replacement or refund is applicable even if sought for within 3 days of delivery.
    </li>
    <li>
      For those products are approved by us for replacement basis user escalation, we will ensure that the pickup 
      is arranged at the earliest and we will ship the replacement as soon as we receive the item from our courier partner.
    </li>
    <li>
      Replacement is subject to availability of stock with us. In case a Replacement is not available, we will refund 
      the amount for the same.
    </li>
  </ul>
</div>


<div>
  <span>
    <h6>Refund <br /></h6>
  </span>
</div>
<div>
  <p>
    Refunds shall be considered for those purchases which are not replaceable which are logged in for 
    Damage / Defective / Different only as per the above mentioned policy. 
    We can process the refund for replacement, only after the product have been received and verified at our warehouse.
    <br />
    For payments done through credit/debit cards or net banking or cash cards,  the refund will be initiated 
    to the same account from which the payment was made within 24-48 business hours of us receiving the products back. 
    It may take 5-7 additional business days for the amount to reflect in your account.
    <br />
    For cash on delivery transactions, we will initiate a bank transfer against the refund amount against the 
    billing details shared by you. This process will be completed within 24-48 business hours of us receiving 
    the products back and your bank details on My Account and/or email from Register email id .It will take an 
    additional 5-7 business days for the amount to reflect in your account. The bank details that are required are as follows:
    
    <br />
  </p>
  <ul>
    <li>Name as on Account</li>
    <li>Name of the Bank</li>
    <li>Account Number</li>
    <li>IFSC Code</li>
    <li>Order Number</li>
  </ul>
  <p>
    In addition, we also provide the hassle-free option of refund through Epanipurii kartz Credit point, which can be used during 
    future purchases should you opt for.
    <br />
    For all approved refunds, the entire amount paid by you including shipping and gift wrapping charges are transferred 
    to your account.
    <br />
    For orders placed through Gift vouchers, refund would be provided in form of a fresh Gift voucher of the same 
    value and the Expiry date will be the same as in the original gift coupons.
    <br />
  </p>
</div>


<div>
  <span>
    <h6>Not eligible for Return & Replacement <br /></h6>
  </span>
</div>
<div>
  <p>
    The following shall not be applicable for Return, Replacement and Refund.
   <br />
  </p>
  <ul>
    <li>Product is damaged due to misuse/overuse</li>
    <li>Returned without original packaging including, price tags, labels, freebies, and other accessories 
      or if original packaging is damaged</li>
    <li>Serial Number is tampered</li>
    <li>If request is initiated after 3 business days of order delivery</li>
  </ul>
</div>


<div>
  <span>
    <h6>Category not eligible for Return & Replacement<br /></h6>
  </span>
</div>
<div>
  <ul>
    <li>Extended Warranty when purchased as a standalone product</li>
    <li>Customized Order</li>
  </ul>
</div>



<div>
  <span>
    <h6><i>Your Account and Registration Obligations</i> <br /></h6>
  </span>
</div>

<div>
  <ul>
    <li>
      If You use the Website, you shall be responsible for maintaining the confidentiality of your 
      Display Name and Password and You shall be responsible for all activities that occur under your 
      Display Name and Password. You agree that if You provide any information that is untrue, inaccurate 
      or We have reasonable grounds to suspect that such information is untrue, inaccurate, or not in accordance
       with this Terms of Use, we shall have the right to indefinitely suspend or terminate or block access of 
       your account on the Website and refuse to provide You with access to the Website.
    </li>
    <li>Your mobile phone number and/or e-mail address is treated as Your primary identifier on the Website. 
      It is your responsibility to ensure that Your mobile phone number and your email address is up to date on 
      the Website at all times. You agree to notify Us promptly if your mobile phone number or e-mail address 
      changes by updating the same on the Website.</li>
    <li>
      You agree that Epanipurii kartz shall not be liable or responsible for the activities or consequences 
      of use or misuse of any information that occurs under your display name in cases where You have failed 
      to update Your revised mobile phone number and/or e-mail address on the Website.
    </li>
  </ul>
</div>


<div>
  <span>
    <h6><i>License and website/Application Access</i> <br /></h6>
  </span>
</div>

<div>
  <p>
    <strong>General: </strong> Epanipurii kartz grants you a limited license to access and make personal use 
    of this website and not to download (other than page caching) or modify it, or any portion of it, except 
    with express written consent of Epanipurii kartz.
    <br />
  </p>


  <p>
    <strong>No license for commercial sale:  </strong> This license does not include any resale or commercial 
    use of this website or its content; any collection and use of any product listing, description, or pricing; 
    copying of account information for the benefit of another merchant; or any use of data mining, or similar data 
    gathering and extraction tools.
    <br />
  </p>

  <p>
    <strong>No framing:  </strong> You may not frame or utilize framing technologies to enclose any trademark, 
    logo, or other proprietary information (including images, text, page layout, or form) of Epanipurii kartz and its
     affiliates without the express written consent.
    <br />
  </p>

  <p>
    <strong>Metatags:  </strong> You may not use any met tags or any other ‘hidden text’ utilizing Epanipurii kartz 
    name or trademarks without the express written consent. Any unauthorized use terminates the permission or license granted.
    <br />
  </p>

  <p>
    <strong>Protection of Your Account: </strong> As discussed further in the website’s privacy policy, by using this website,
     you agree that you are responsible for maintaining the confidentiality of your account and password and for restricting 
     access to your computer and agree to accept responsibility for all activities that occur under your account or password.
    <br />
  </p>

  <p>
    <strong>Use by Children: </strong> As discussed further in the website Privacy Policy, Epanipurii kartz does sell 
    products for children and adults. If you are underage of 18 years, you may use Epanipurii kartz only with involvement 
    of a parent or guardian. Epanipurii kartz and its affiliates reserve the right to refuse service, terminate accounts, 
    remove, or edit content, or cancel orders in their sole discretion.
    <br />
  </p>


  <div>
    <span>
      <h6>Reviews, Comments, Communications, and other content <br /></h6>
    </span>
  </div>
  
  <div>

  <p>
    <strong>Nature of content: </strong> Visitors may post reviews, comments and other content; send e-cards and other 
    communications; and submit suggestions, ideas, comments, questions or other information, as long as the content is not 
    illegal, obscene, threatening, defamatory, invasive of privacy, infringing of intellectual property rights to 
    otherwise injuries to third party or objectionable and does not consist of or contains software virus, political 
    campaigning, commercial solicitation, mass mailing or any form of spam.
    <br />
  </p>

  <p>
    <strong>False information: </strong> You may not use false email address, impersonate any person or entity, 
    or otherwise mislead as to the origin of a card or other content. Inifbeam.com reserves the right (but not the obligation)
     to remove or edit such content but does not regularly review posted contents.
    <br />
  </p>

  <p>
    <strong>Rights Granted: </strong> If you do post content or submit material and unless we indicate otherwise, 
    you grant Epanipurii kartz and its affiliates a non-exclusive, royalty free, perpetual, irrevocable, and fully 
    sub-licensed right to use, reproduce, modify, adapt, publish, translate, create derivative work from, distribute, 
    and display such content throughout the world in any media. You grant Epanipurii kartz and its affiliate sites and 
    sub-license the right to use the name that you submit in connection with such content if Epanipurii kartz chooses.
    <br />
  </p>

  <p>
    <strong>Right Owned: </strong> You represent and warrant that you own all the rights or otherwise or control all 
    the rights to the content that you post; that the content is accurate; that the use of the content to supply does 
    not violate this policy and will not cause injury to any person or entity and that you will indemnify Epanipurii kartz 
    its affiliate for all claims resulting from the content you supply. Epanipurii kartz has the right but not the obligation 
    to monitor and edit or remove any activity or content. Epanipurii kartz takes no responsibility and assumes no liability 
    for any content posted by you or any third party.
    <br />
  </p>

</div>



<div>
  <span>
    <h6>Risk of Loss <br /></h6>
  </span>
</div>
<div>
  <p>
    All items purchased from Epanipurii kartz are made pursuant to the shipment contract. This means that the risk of loss and title for 
    such item passes on to you upon the products delivery.
   <br />
  </p>
</div>



<div>
  <span>
    <h6>Product Description<br /></h6>
  </span>
</div>
<div>
  <p>
    Epanipurii kartz and its affiliates attempt to be as accurate as possible. However, Epanipurii kartz makes not 
    warranties that the product description and any other content of its site are accurate, complete, reliable, and 
    current or error free. The product offered by Epanipurii kartz itself is not as described and its sole remedy is
     to return in its unused condition.

   <br />
  </p>
</div>


<div>
  <span>
    <h6>Site Policies, Modification, and Severability<br /></h6>
  </span>
</div>
<div>
  <p>
    Please review our other policies. We reserve the right to make changes to our website, policies, and these 
    Terms and Conditions at any time. If any of these conditions shall be deemed invalid, void, or for any reason 
    unenforceable, that condition shall be deemed severable and will not affect the validity and enforceability of 
    any remaining conditions.

   <br />
  </p>
</div>


<div>
  <span>
    <h6>Intellectual Property Rights<br /></h6>
  </span>
</div>
<div>
  <p>
    Copyright Protection All content included on this site, such as text, graphics, logos, button icons, audio clips, 
    digital downloads, data compilations and software, is the property of Epanipurii kartz or its affiliate sites 
    and protected by the Indian Copyright law. The compilation of all the content on this site is the exclusive 
    property if Epanipurii kartz and protected by Indian Copyright law. All software used in this site is the property 
    of Epanipurii kartz and is protected under the Indian Copyright law.

   <br />
  </p>
</div>


<div>
  <span>
    <h6>Trademarks<br /></h6>
  </span>
</div>
<div>
  <p>
    <strong>Protected Marks:</strong> Epanipurii kartz, design, products, and other marks indicated are registered 
    trademarks of Epanipurii kartz.
    <br />
  </p>

  <p>
    <strong>Protected Graphics: </strong> All Epanipurii kartz graphics, logos, page headers, button icons, 
    scripts and service names are trademarks of Epanipurii kartz. Trademarks and trade dress may not be used in 
    connections with any product or service that is not of Epanipurii kartz. All other trademarks not owned by 
    Epanipurii kartz or its affiliates that appear on or its affiliate sites are the property of their respective 
    owners, who may or may not be affiliated with, connected to, associated with Epanipurii kartz or its affiliates.
    <br />
  </p>

</div>

<div>
  <span>
    <h6>Governing Law and Jurisdiction <br /></h6>
  </span>
</div>
<div>
  <p>
    These terms and conditions will be construed only in accordance with the laws of India. 
    In respect of all matters/disputes arising out of, in connection with or in relation to these terms and conditions 
    or any other conditions on this website, All Disputes are subject to the exclusive jurisdiction of competent 
    courts and forums in Patna , Bihar , India only. <br />
  </p>
</div>




  <div>
    <span>
      <h6>Acceptance of Terms & Conditions <br /></h6>
    </span>
  </div>
  <div>
    <ul>
      <li>
        This document is an electronic record in terms of Information Technology Act, 2000 and rules there under 
        as applicable and the amended provisions pertaining to electronic records in various statutes as amended by 
        the Information Technology Act, 2000. This electronic record is generated by a computer system and does
         not require any physical or digital signatures.
      </li>
      <li>
        •	This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology 
        (Intermediaries guidelines) Rules, 2011 that require publishing the rules and regulations, privacy policy and 
        Terms of Use for access or usage of Epanipurii kartz  website. The domain name Epanipurii kartz 
        (hereinafter referred to as "Website") is owned by Epanipurii kartz. 
      </li>
      <li>
        Your use of the Website and services and tools are governed by the following terms and conditions ( "Terms of Use" ) 
        as applicable to the Website including the applicable policies which are incorporated herein by way of reference. 
        If You transact on the Website, You shall be subject to the policies that are applicable to the Website for such 
        transaction. By mere use of the Website, You shall be contracting with Epanipurii kartz and these terms and 
        conditions including the policies constitute Your binding obligations, with Epanipurii kartz. To these Terms 
        of Use, wherever the context so requires "You" or "User" shall mean any natural or legal person who has agreed 
        to being a buyer on the Website by providing Registration Data while registering on the Website as Registered 
        User using the Computer systems. Epanipurii kartz allows the User to surf the Website or making purchases without 
        registering on the Website. The term "We" , "Us" , "Our" shall mean Epanipurii kartz.
      </li>
      <li>
        When You use any of the services provided by Us through the Website, including but not limited to, 
        (e.g. Product Reviews, Seller Reviews), You will be subject to the rules, guidelines, policies, terms, and conditions 
        applicable to such service, and they shall be deemed to be incorporated into this Terms of Use and shall be 
        considered as part and parcel of this Terms of Use. We reserve the right, at Our sole discretion, to change, 
        modify, add, or remove portions of these Terms of Use, at any time without any prior written notice to You. 
        It is Your responsibility to review these Terms of Use periodically for updates / changes. Your continued use 
        of the Website following the posting of changes will mean that You accept and agree to the revisions. 
        As long as You imply with these Terms of Use, We grant You a personal, non-exclusive, non-transferable, 
        limited privilege to enter and use the Website.
      </li>
    </ul>
  </div>


  <div>
    <span>
      <h6>Disclaimer of warranties and Limitation of Liability <br /></h6>
    </span>
  </div>
  <div>
    <p>
      THIS SITE IS PROVIDED BY Water N Spices Foodsz Private Limited ON AN “AS
      IS” AND “AS AVAILABLE” BASIS. Water N Spices Foodsz Private Limited MAKES
      NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO
      THE OPERATION OF THIS SITE OR THE INFORMATION, CONTENT, MATERIALS, OR
      PRODUCTS INCLUED ON THIS SITE. YOU EXPRESSLY AGREE THAT YOUR USE OF THIS
      SITE IS AT YOUR SOLE RISK. TO THE FULL EXTENT PERMISSIBLE BY APPLICABLE
      LAW, Water N Spices Foodsz Private Limited DISCLAIMS ALL WARRANTIES,
      EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF
      MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. Water N Spices
      Foodsz Private Limited DOES NOT WARRANT THAT THE SITE, ITS SERVERS, OR
      EMAIL SENT FROM Water N Spices Foodsz Private Limited ARE FREE OF VIRUS OR
      OTHER HARMFUL COMPONENTS. Water N spices Foodsz Private Limited WILL NOT
      BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE,
      INCLUDING, BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE AND
      CONSEQUENTIAL DAMAGES.<br />
    </p>
  </div>
</div>
