<form [formGroup]="registerForm" (ngSubmit)="startRegistration()">
  <div class="col-md-12 col-sm-12">
    <div class="form-group">
      <label class="form-check-label hint-text" for="password">
        Password
      </label>
      <input
        id="password"
        type="password"
        placeholder="8-32 Characters"
        [formControlName]="'password'"
        [ngClass]="{
          inputError: showError('password')
        }"
      />
      <div *ngIf="showError('password')">
        <div
          *ngIf="registerForm.get('password')?.hasError('required')"
          class="error-text"
        >
          Password is required
        </div>
        <div
          *ngIf="registerForm.get('password')?.hasError('pattern')"
          class="error-text"
        >
          Password must have 8-32 character
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 col-sm-12">
    <div class="form-group">
      <label class="form-check-label hint-text" for="cpassword">
        Confirm Password
      </label>
      <input
        id="cpassword"
        type="password"
        placeholder="Re-enter your Password"
        [formControlName]="'confirmPassword'"
        [ngClass]="{
          inputError: showError('confirmPassword')
        }"
      />
      <div *ngIf="showError('confirmPassword')">
        <div
          *ngIf="registerForm.get('confirmPassword')?.hasError('required')"
          class="error-text"
        >
          Re-enter your Password
        </div>
        <div
          *ngIf="
            registerForm.get('confirmPassword')?.hasError('passwordNotMatched')
          "
          class="error-text"
        >
          Passwords don't match
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12 col-sm-12">
    <div class="form-group w-100">
      <button
        type="submit"
        class="primary-button"
        [disabled]="registerForm.invalid"
      >
        <span
          *ngIf="loading"
          class="spinner-border spinner-border-sm"
          role="status"
        ></span>
        <span *ngIf="!loading">Register</span>
      </button>
    </div>
  </div>
</form>
