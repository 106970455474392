<app-blank-header></app-blank-header>

<div class="container">

  <div>
    <span>
      <h2 class="top-heading">Order Cancellation and Return Policy<br /></h2>
    </span>
  </div>

  <div>
    <span>
      <h4 class="text-center">Cancellation Policy<br /></h4>
    </span>
  </div>

  <div>
    <p>
        The customer can choose to cancel an order any time before it's dispatched. The order cannot be cancelled 
        once it’s out for delivery.
    <br />
    </p>
    <p>
        The time window for cancellation varies based on different categories and the order cannot be cancelled 
        once the specified time has passed.
    <br />
    </p>

    <p>
        In some cases, the customer may not be allowed to cancel the order for free, post the specified time and a 
        cancellation fee will be charged. The details about the time window mentioned on the product page or order 
        confirmation page will be considered final.
    <br />
    </p>

    <p>
        In case of any cancellation from the seller due to unforeseen circumstances, a full refund will be 
        initiated for prepaid orders.
    <br />
    </p>

    <p>
        Epanipurii kartz reserves the right to accept the cancellation of any order. Water N Spices Foodsz Pvt Ltd 
        also reserves the right to waive off or modify the time window or cancellation fee from time to time.
    <br />
    </p>
    
  </div>

  <div>
    <span>
      <h4 class="text-center">Returns Policy<br /></h4>
    </span>
  </div>
  <div>
    <p>
        Returns is a scheme provided by respective sellers directly under this policy in terms of which the option of 
        exchange, replacement and/ or refund is offered by the respective sellers to you. All products listed under 
        a particular category may not have the same returns policy. For all products, the returns/replacement policy 
        provided on the product page shall prevail over the general returns policy. Do refer the respective item's 
        applicable return/replacement policy on the product page for any exceptions to this returns policy and the 
        table below.
    </p>

      <p>
        The return policy is divided into three parts; Do read all sections carefully to understand the conditions 
        and cases under which returns will be accepted.
      </p>

      <p>
        Part 2 - Returns Pick-Up and Processing In case of returns where you would like item(s) to be picked up from 
        a different address, the address can only be changed if pick-up service is available at the new address 
        During pick-up, your product will be checked for the following conditions:
      </p>

      <p>
        The field executive will refuse to accept the return if any of the above conditions are not met.
      <br />
    </p>

    <p>
        For any products for which a refund is to be given, the refund will be processed once the returned product
         has been received by the seller.
      <br />
    </p>

    <p>
        Part 3 - General Rules for a successful Return
      <br />
    </p>

    <ol type="1">
        <li>
            In certain cases where the seller is unable to process a replacement for any reason whatsoever, 
            a refund will be given.
        </li>
        <li>
            In cases where a product accessory is found missing/damaged/defective, the seller may either 
            process a replacement of the particular accessory or issue an eGV for an amount equivalent to the 
            price of the accessory, at the seller’s discretion.
        </li>
        <li>
            During open box deliveries, while accepting your order, if you received a different or a 
            damaged product, you will be given a refund (on the spot refunds for cash-on-delivery orders). 
            Once you have accepted an open box delivery, no return request will be processed, except for 
            manufacturing defects. In such cases, these category-specific replacement/return general conditions 
            will be applicable. 
        </li>
        <li>
            For products where installation is provided by Epanipurii kartz's service partners, 
            do not open the product packaging by yourself. Epanipurii kartz authorised personnel shall help 
            in unboxing and installation of the product.
        </li>
        <li>
            For Furniture, any product-related issues will be checked by authorised service personnel 
            (free of cost) and attempted to be resolved by replacing the faulty/ defective part of the product. 
            Full replacement will be provided only in cases where the service personnel opines that replacing the 
            faulty/defective part will not resolve the issue.'
        </li>
    </ol>

    <p>
       Epanipurii kartz holds the right to restrict the number of returns created per order unit, post the 
       evaluation of the product/order defect is undertaken by Epanipurii kartz authorized representative.


      <br />
    </p>


</div>

</div>
