<div class="partner-area ptb-100">
  <div class="container">
    <div class="section-title">
      <span>Socials</span>
      <h2>Follow us on</h2>
    </div>

    <div class="row socials-row">
      <div class="social-icons">
        <a href="https://g.page/Epanipuriikartz?we" target="_blank">
          <i class="icofont-google-plus"></i>
        </a>
      </div>
      <div class="social-icons">
        <a
          href="https://www.linkedin.com/company/e-panipuri-cart"
          target="_blank"
        >
          <i class="icofont-linkedin"></i>
        </a>
      </div>

      <div class="social-icons">
        <a href="https://www.facebook.com/epanipuricart" target="_blank">
          <i class="icofont-facebook"></i>
        </a>
      </div>
      <div class="social-icons">
        <a href="https://twitter.com/Epanipurikartz" target="_blank">
          <i class="icofont-twitter"></i>
        </a>
      </div>
      <div class="social-icons">
        <a href="https://www.instagram.com/epanipuriikartz" target="_blank">
          <i class="icofont-instagram"></i>
        </a>
      </div>
      <div class="social-icons">
        <a
          href="https://www.youtube.com/channel/UCtTWRxZArJDl2PDZTgLygCQ"
          target="_blank"
        >
          <i class="icofont-youtube-play"></i>
        </a>
      </div>
    </div>
  </div>
  <div class="container mt-5">
    <div class="row" >
      <div
        class="col-lg-5 offset-lg-7 col-md-10 offset-md-1 mb-2 text-center"
      >
        <button class="btn btn-primary" (click)="openRegistrationPage()">
          Partner With Us ->
        </button>
      </div>
    </div>
  </div>
</div>
