<section class="stunning-things ptb-100">
    <div class="container">
        <div class="section-title">
            <span>For Your Comfort</span>
            <h2>Stunning Things</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-restaurant"></i>
                    </div>
                    <h3>High Quality Foods</h3>
                    <p>Etiam feugiat eleifend est, sed luctus odio tempor vitae. Vivamus maximus scelerisque ipsum nec commodo.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-cocktail"></i>
                    </div>
                    <h3>Inspiring Recipes</h3>
                    <p>Etiam feugiat eleifend est, sed luctus odio tempor vitae. Vivamus maximus scelerisque ipsum nec commodo.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-chicken"></i>
                    </div>
                    <h3>Salutary Meals</h3>
                    <p>Etiam feugiat eleifend est, sed luctus odio tempor vitae. Vivamus maximus scelerisque ipsum nec commodo.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-chef"></i>
                    </div>
                    <h3>Veteran Staff</h3>
                    <p>Etiam feugiat eleifend est, sed luctus odio tempor vitae. Vivamus maximus scelerisque ipsum nec commodo.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-chicken-fry"></i>
                    </div>
                    <h3>Pristine Ingredients</h3>
                    <p>Etiam feugiat eleifend est, sed luctus odio tempor vitae. Vivamus maximus scelerisque ipsum nec commodo.</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="icofont-fast-delivery"></i>
                    </div>
                    <h3>Express Delivery</h3>
                    <p>Etiam feugiat eleifend est, sed luctus odio tempor vitae. Vivamus maximus scelerisque ipsum nec commodo.</p>
                </div>
            </div>
        </div>
    </div>

    <div class="line-bg"><img src="assets/img/line.png" alt="line"></div>
</section>