<app-blank-header></app-blank-header>
<div class="container">

  <div>
    <span>
      <h2 class="top-heading">Refund Policy<br /></h2>
    </span>
  </div>

  <div>
    <span>
      <h6>How will I get my refund?<br /></h6>
    </span>
  </div>

  <div>
    <p>
        Refund will be credited to the same source of payment from which payment was received, after deducting 
        the cost of return (where applicable), once returned is received.  
    <br />
    </p>
    <p>
        If you desire to return a product, then you shall be entitled to receive only the amount actually paid by 
        you for the purchase of the product.
    <br />
    </p>

    <p>
        In the event any product is returned, then any offer, promotion, discount applied to such product shall 
        be forfeited.
    <br />
    </p>

    <p>
        If you have made the payment through your bank account, the refund will be credited to the same bank account 
        from which payment was received after deducting the cost of return (where applicable) once the return is received.
    <br />
    </p>

    <p>
        If you have made the payment as cash on delivery, you will be asked to provide us with your bank account details
         and the refund amount will be credited to the bank account details provided by you. Company shall not be 
         liable to you in case incorrect bank account details have been provided by you.
    <br />
    </p>

    <p>
        In case of any discrepancies regarding receipt of refund amount, Company may request for additional information 
        such as bank statement or any other relevant document.
    <br />
    </p>
    
  </div>

  
  <div>
    <span>
      <h6>When will I get my refund?<br /></h6>
    </span>
  </div>

  <div>
    <p>
        Following are the processing timelines after the product is received by us or when the seller notifies us 
        of the receipt of the products.
    <br />
    </p>
   
  </div>

  <div>
    
    <table style="width:100%">
        <tr>
          <th>Refund Method</th>
          <th>Refund Time Frame</th> 
        </tr>
        <tr>
          <td>Credit Card, Debit Card, Net Banking, UPI Linked Bank Account</td>
          <td>3-7 Business Days</td>
        </tr>
      </table>

  </div>

  <div>
    <h6>Instant Refund:</h6>

    <p>
        We offer instant refund whereby the refund will be triggered within 4 (four) hours of the product being 
        picked up and marked by the delivery partners as successfully picked up (“Instant Refund”). The refund will
         be reflected to the bank account  within 3 -7 Business Days. The following are the conditions which are 
         required to be met for the return to be eligible for an Instant Refund:
    <br />
    </p>

    <p>
        Instant Refunds are only available for cash on delivery orders.
    <br />
    </p>

    <p>
        The product which is being returned should have successfully undergone a quality check by the delivery 
        partners and should have been marked as successfully picked up for return on the systems of . Epanipurii 
        kartz reserves the right to not accept the return in the event the product is damaged, incorrect or for 
        such other reasons as Epanipurii kartz deems fit. 

    <br />
    </p>

    <p>
        In the event where the delivery partner is unable to mark the product as successfully picked up in the 
        systems of Epanipurii kartz, as a result of technical issues, Epanipurii kartz shall not be liable to process
         the Instant Refund. 
    <br />
    </p>

    <p>
        Further, Epanipurii kartz shall not be held liable for not processing the Instant Refund for reasons 
        attributable to the delivery partner, which include instances as, product being marked canceled instead 
        of picked up, tracking details not available for the product picked up, etc. In such instances, Epanipurii
         kartz may not be able to process the Instant Refund.
    <br />
    </p>

    <p>
        Instant Refunds will not be processed in case of instances whereby the systems of Epanipurii kartz cannot 
        generate a refund without manual intervention, i.e., it is not a system generated refund. Manual intervention 
        is typically required in the following instances: 
    <br />
    </p>

    <p>
        In the event the refund request is raised for a missing product or a product that was not delivered to the customer. 
    <br />
    </p>
    
    <p>
        In the event that  the delivery partner is unable to update accurate details in the systems of Epanipurii kartz 
        regarding the product that has been picked up including tracking details for the product. 
    <br />
    </p>

    <p>
        In the event the customer has opted for self shipment, for any reason, and not routed the return via the delivery 
        agents assigned by Epanipurii kartz. In the event that the customer has mistakenly returned additional products 
        along with the products for which the return was initiated. 


    <br />
    </p>


    <p>
        Instant Refunds are not available for customers who have purchased products from Resellers on the Epanipurii kartz 
        platform.  Epanipurii kartz may not be able to process Instant Refunds in the event of unavailability of services 
        due to any reason including technical issues and downtime,  which may include: (a) downtime in the bank servers which
         will hamper Epanipurii kartz’s ability to process the refunds, (b) downtime in the Epanipurii kartz platform or 
         communication channel which disrupts the ability of the delivery partner to update the status of the pick up, 
         or (c) other technical reasons which disrupts the process of return and refunds, as may be applicable.
    <br />
    </p>

    <p>
        Instant Refunds will not be processed if the bank details or UPI ID is not provided or is inaccurate. 
    <br />
    </p>

    <p>
        Epanipurii kartz reserves the right in its sole discretion to refuse Instant Refund for reasons including 
        but not limited to: (i) any fraud or malpractice committed by the user and detected by Epanipurii kartz at any
         point of time, (ii) for any other reason that Epanipurii kartz may deem fit. 
    <br />
    </p>

    <h6>Miscellaneous </h6>

    <p>
        Please note that all the customer claims and requests for return/refund shall be subject to  Epanipurii 
        kartz’s satisfaction. 
    <br />
    </p>

    <p>
        Epanipurii kartz reserves the right, at its sole discretion, to amend, change, modify, add or remove any portion 
        of this policy at any time without any prior written notice to you. It is your responsibility to review this policy 
        periodically for any updates/ changes. For any further queries regarding return, exchange or refund, please reach out to customer support at info@epanipuricart.in

    <br />
    </p>

  </div>


</div>
