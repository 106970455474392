<section id="features" class="features-area ptb-100 pb-0">
    <div class="container">
        <div class="section-title">
            <span>Salient Points</span>
            <h2>What We Offer</h2>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-soft-drinks"></i>
                    </div>
                    <div class="features-content">
                        <h3>Touchless Operation</h3>
                        <p>We enable our franchise members to operate their carts in an contactless manner.</p>
                    </div>
                </div>

                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-box"></i>
                    </div>
                    <div class="features-content">
                        <h3>Food Safety</h3>
                        <p>Safety is our top priority when it comes to serving cutomers.</p>
                    </div>
                </div>

                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-chicken-fry"></i>
                    </div>
                    <div class="features-content">
                        <h3>Hygiene</h3>
                        <p>All carts serve hygienic food and in a clean manner.</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="features-img">
                    <img src="assets/img/Pani-puri-feature.jpg" alt="features-img">
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-food-basket"></i>
                    </div>
                    <div class="features-content">
                        <h3>Variety</h3>
                        <p>We offer our customer tons of choices to choose from and satisfy their appetite</p>
                    </div>
                </div>

                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-cocktail"></i>
                    </div>
                    <div class="features-content">
                        <h3>Contactless Serving</h3>
                        <p>All carts fully automated and customers are served contactlessly.</p>
                    </div>
                </div>

                <div class="single-features">
                    <div class="icon">
                        <i class="icofont-cup-cake"></i>
                    </div>
                    <div class="features-content">
                        <h3>Quality Food</h3>
                        <p>Along with hygiene we ensure our food quality is top notch with a enjoyable taste .</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>