<app-blank-header></app-blank-header>
<div class="container">

  <div>
    <span>
      <h2 class="top-heading">Contact Us<br /></h2>
    </span>
  </div>

  <div>
   
      <p><strong>Regd Off :</strong> 203 , Liya Complex , PP compound , Hindpiri , Main Road , Ranchi -834001 (Jharkhand)</p>
      <p><strong>Branch & Workshop :</strong> Plot no. 64 , Unit 7 , Suryanagar , Bhubaneswar-751003 (Odisha)</p>
      <p><strong>Website :</strong>www.epanipuricart.com | www.epanipuricart.in | www.epanipuricart.co.in</p>

      <p><strong>For Sales Inquirty :</strong>+91 76  76 135 136</p>
      <p><strong>Mail : </strong>bd@epanipuricart.in</p>

      <p><strong>For Customer Support : </strong>+91 62 00 590 525</p>
      <p><strong>Mail : </strong>services@epanipuricart.co.in</p>

      <p><strong>GST : </strong>21AACCW5277E1ZH</p>
      <p><strong>MSME : </strong>JH-20-0002827</p>
      <p><strong>FSSAI : </strong>12022999000164</p>

  </div>


</div>
