<app-blank-header></app-blank-header>
<div class="container-fluid h-100">
  <div class="row h-100 row-1 container-row">
    <div class="col-md-6 offset-md-3 container-col card-non-hover">
      <div style="margin-top: 2rem" class="otp-heading-div">
        <div
          class="sub-heading-text-1-semi-bold"
          style="margin: 1rem 0 2rem 0; padding: 0 1rem"
          (click)="login()"
        >
          Login
        </div>
        <div class="icon-div" (click)="onClickClose()">
          <fa-icon class="float-right" [icon]="faHome"></fa-icon>
        </div>
      </div>
      <form [formGroup]="loginForm" (ngSubmit)="login()">
        <div class="row justify-content-center">
          <div class="col-md-7 col-sm-12">
            <div class="form-group">
              <label class="form-check-label hint-text" for="emailId">
                Email Id
              </label>
              <input
                type="text"
                id="emailId"
                placeholder="Enter your Email ID"
                [formControlName]="'email'"
              />
            </div>
          </div>
          <div class="col-md-7 col-sm-12">
            <div class="form-group">
              <label class="form-check-label hint-text" for="password">
                Password
              </label>
              <input
                id="password"
                type="password"
                placeholder="Enter your password"
                [formControlName]="'password'"
                #passwordField
              />
            </div>
          </div>
          <div class="col-md-7 col-sm-12">
            <div class="form-group w-100 text-center">
              <button
                type="submit"
                class="primary-button"
                [disabled]="loginForm.invalid"
              >
                <span
                  *ngIf="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                ></span>
                <span *ngIf="!loading">Login</span>
              </button>
            </div>
          </div>
          <hr class="w-100" />
          <div class="col-md-12 col-sm-12 row">
            <div>
              <p>Not a User? <a [routerLink]="'/register'">Register Now</a></p>
            </div>
            <div class="ml-md-auto">
              <p>
                Forgot Password?
                <a [routerLink]="'/reset'">Reset Password</a>
              </p>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
